import { toast } from 'react-hot-toast'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import UrlModal from 'components/Modal/UrlModal'
import ModalBody from 'components/Modal/ModalBody'
import ModalFooter from 'components/Modal/ModalFooter'
import Button from 'components/Button'
import { menusQueryKey, useEditMenuMutation, useGetSingleMenuQuery } from '../queries'
import InputField from 'components/Form/Inputs/InputField'
import SubmitButton from 'components/Form/SubmitButton'
import { queryClient } from 'utils/queryClient'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import FormRow from 'components/Form/FormRow'
import { getMenuDateOptions } from './addMenuForm'
import SelectField from 'components/Form/Inputs/SelectField'
import { useEffect } from 'react'
import { formatDate, getDayOfWeek } from '../editMenu/days'

type Inputs = {
    name: string
    startDate: string
    endDate: string
}

const schema = z
    .object({
        name: z.string().min(1, 'Required'),
        startDate: z.string(),
        endDate: z.string()
    })
    .refine(
        ({ startDate, endDate }) => {
            const parsedStart = new Date(startDate)
            const parsedEnd = new Date(endDate)

            return parsedStart <= parsedEnd
        },
        {
            path: ['endDate'],
            message: 'End date cannot be before start date.'
        }
    )

function EditMenuDetailsForm() {
    const navigate = useNavigate()
    const { menuId } = useParams()

    const { data: getSingleMenuData } = useGetSingleMenuQuery(menuId || '')

    const { mutateAsync: editMenu } = useEditMenuMutation()

    const dateOptions = getMenuDateOptions()

    const methods = useForm<Inputs>({
        mode: 'all',
        resolver: zodResolver(schema),
        defaultValues: {
            name: getSingleMenuData?.name,
            startDate: getSingleMenuData?.start_date,
            endDate: getSingleMenuData?.end_date
        }
    })

    const {
        handleSubmit,
        formState: { isDirty, isValid, isSubmitting },
        watch,
        trigger
    } = methods

    const startDateValue = watch('startDate')

    useEffect(() => {
        trigger('endDate')
    }, [startDateValue])

    const onSubmit: SubmitHandler<Inputs> = async ({ name, startDate, endDate }) => {
        await editMenu(
            {
                menuId: menuId || '',
                attributes: {
                    name,
                    end_date: endDate,
                    start_date: startDate
                }
            },
            {
                onSuccess: (res) => {
                    toast.success(res.message)
                    queryClient.invalidateQueries(menusQueryKey())
                    navigate(-1)
                }
            }
        )
    }

    return (
        <div>
            <UrlModal title='Edit Menu' desc='Update the name of your menu' onClose={() => navigate(-1)}>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody>
                            <FormRow>
                                <InputField.HookForm label='Name' name='name' />
                            </FormRow>
                            <FormRow>
                                <SelectField.HookForm
                                    label='Start Date'
                                    name='startDate'
                                    options={dateOptions.map((date) => ({
                                        label: `${getDayOfWeek(date)} - ${formatDate(date, 'dd/mm/YYYY')}`,
                                        value: formatDate(date)
                                    }))}
                                />
                            </FormRow>
                            <FormRow>
                                <SelectField.HookForm
                                    label='End Date'
                                    name='endDate'
                                    options={dateOptions.map((date) => ({
                                        label: `${getDayOfWeek(date)} - ${formatDate(date, 'dd/mm/YYYY')}`,
                                        value: formatDate(date)
                                    }))}
                                    showErrorBeforeTouched
                                />
                            </FormRow>
                        </ModalBody>
                        <ModalFooter
                            buttons={[
                                <Button key={1} color='secondary' onClick={() => navigate(-1)}>
                                    Back
                                </Button>,
                                <SubmitButton key={2} isSubmitting={isSubmitting} isValid={isValid} isDirty={isDirty} text='Save' />
                            ]}
                        />
                    </form>
                </FormProvider>
            </UrlModal>
        </div>
    )
}

export default EditMenuDetailsForm
