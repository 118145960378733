import { useParams, Link, useNavigate, Outlet } from 'react-router-dom'
import { useGetSingleMenuQuery, useUpdateMenuRecipeMutation } from '../queries'
import { useGetRecipesQuery } from 'containers/recipes/queries'
import Loader from 'components/Loader'
import { PencilSquareIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/solid'
import { LightBulbIcon } from '@heroicons/react/24/outline'
import EditMenuRecipe from 'containers/menus/components/editMenuRecipe'
import Button from 'components/Button'
import { Days, formatDate } from './days'
import { Droppable } from './days/droppable'
import { createContext, useContext, useState } from 'react'
import classnames from 'classnames'
import { Dropdown } from 'components/Dropdown'

export const EditMenuIsDraggingContext = createContext({
    isDragging: false,
    // eslint-disable-next-line
    setIsDragging: (value: boolean) => {
        /** */
    }
})

function EditMenuIsDraggingContextProvider({ children }: { children: React.ReactNode }) {
    const [isDragging, setIsDragging] = useState(false)

    return <EditMenuIsDraggingContext.Provider value={{ isDragging, setIsDragging }}>{children}</EditMenuIsDraggingContext.Provider>
}

function EditMenu() {
    const { menuId } = useParams()
    const navigate = useNavigate()

    const { isDragging, setIsDragging } = useContext(EditMenuIsDraggingContext)

    const { data: getSingleMenuData, isLoading: isGetSingleMenuLoading, isError: isGetSingleMenuError } = useGetSingleMenuQuery(menuId || '')
    const { data: getRecipesData, isFetching: isGetRecipesFetching, isError: isGetRecipesError } = useGetRecipesQuery()
    const { mutate: updateMenuRecipe } = useUpdateMenuRecipeMutation()

    if (isGetSingleMenuLoading || isGetRecipesFetching) return <Loader fullPage />
    if (isGetSingleMenuError || !getSingleMenuData || isGetRecipesError || !getRecipesData) return <h1>Menu error</h1>

    const { name, id, recipes, start_date, end_date } = getSingleMenuData

    function getDateOptions() {
        const oneDay = 24 * 60 * 60 * 1000

        const startDate = new Date(start_date)
        const endDate = new Date(end_date)

        const dates = [start_date]

        let currentDateAsTime = startDate.getTime()

        for (let i = 0; i < 20; i++) {
            currentDateAsTime = currentDateAsTime + oneDay
            const currentDateAsDate = new Date(currentDateAsTime)

            if (currentDateAsDate > endDate) {
                break
            }

            dates.push(formatDate(currentDateAsDate))
        }

        return dates
    }

    const dateOptions = getDateOptions()

    const renderRecipesWithNoDay = () => {
        return (
            <div>
                <Droppable
                    className={classnames('h-[32px] w-72 flex items-center rounded-lg pl-2', {
                        'bg-primary-50 border-primary border-2 border-dashed ml-[-2px]': isDragging
                    })}
                    onDragOver={(e) => {
                        e.preventDefault()

                        e.dataTransfer.dropEffect = 'move'
                    }}
                    onDrop={(e) => {
                        e.preventDefault()

                        const data = e.dataTransfer.getData('application/my-app')
                        const recipeId: number = JSON.parse(data)?.recipeId

                        updateMenuRecipe({ menuId: menuId?.toString() || '', recipeId: recipeId.toString(), attributes: { day: null } })

                        setIsDragging(false)
                    }}
                >
                    <h4>No Day Assigned</h4>
                </Droppable>
                <ul className='mt-2 pl-4'>
                    {[...recipes]
                        .filter(({ day_of_week }) => !dateOptions.includes(day_of_week.day || ''))
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((recipe) => (
                            <EditMenuRecipe key={recipe.id} recipe={recipe} menuId={id} />
                        ))}
                </ul>
            </div>
        )
    }

    return (
        <div className='p-4'>
            <div className='flex justify-between'>
                <Link to='/menus'>Back to menus</Link>
                <Dropdown
                    dropdownClassName='h-8 w-8'
                    menuButtonClassName='!bg-primary !text-white w-full h-full flex justify-center items-center rounded-full'
                    menuButton={<EllipsisHorizontalIcon className='size-6' style={{ transform: 'scale(400%)' }} />}
                    menuItems={[
                        <Dropdown.MenuItem.Link key='1' to={`/menus/edit/${id}/random-recipes`}>
                            <LightBulbIcon className='w-4 text-primary' />
                            Random recipes
                        </Dropdown.MenuItem.Link>
                    ]}
                />
            </div>
            <div className='flex mb-7 mt-2'>
                <div>
                    <h2>{name}</h2>
                    <p className='text-xs text-gray-400'>
                        {formatDate(new Date(start_date), 'dd/mm/YYYY')} - {formatDate(new Date(end_date), 'dd/mm/YYYY')}
                    </p>
                </div>
                <button className='ml-4 h-fit mt-2' type='button' onClick={() => navigate(`/menus/edit/${id}/details`)}>
                    <PencilSquareIcon className='w-5 text-primary hover:text-primary-hover' />
                </button>
            </div>
            <Button className='mb-4' onClick={() => navigate(`/menus/edit/${menuId}/add-recipe`)}>
                Add Recipe
            </Button>
            <p className='mb-4'>Drag and drop recipes to change the day</p>
            {renderRecipesWithNoDay()}
            <Days recipes={recipes} menuId={Number(menuId)} days={dateOptions} />
            <Outlet />
        </div>
    )
}

function EditMenuWithContext() {
    return (
        <EditMenuIsDraggingContextProvider>
            <EditMenu />
        </EditMenuIsDraggingContextProvider>
    )
}

export default EditMenuWithContext
