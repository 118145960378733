import { useContext } from 'react'
import EditMenuRecipe from '../../components/editMenuRecipe'
import { useUpdateMenuRecipeMutation, useWeatherQuery } from '../../queries'
import { Menu, MenuRecipe, Weather } from '../../types'
import { Droppable } from './droppable'
import { EditMenuIsDraggingContext } from '..'
import classnames from 'classnames'

export const oneDay = 24 * 60 * 60 * 1000

export function addDays(date: Date, daysToAdd: number) {
    return new Date(date.getTime() + daysToAdd * oneDay)
}

export function getDayOfWeek(date: Date) {
    const dayNumber = date.getDay()

    switch (dayNumber) {
        case 1:
            return 'Monday'
        case 2:
            return 'Tuesday'
        case 3:
            return 'Wednesday'
        case 4:
            return 'Thursday'
        case 5:
            return 'Friday'
        case 6:
            return 'Saturday'
        case 0:
            return 'Sunday'
        default:
            return ''
    }
}

type DateFormats = 'YYYY-MM-DD' | 'dd/mm/YYYY'
export function formatDate(date: Date, format: DateFormats = 'YYYY-MM-DD'): string {
    const fullYear = date.getFullYear()
    const month = date.getMonth() + 1
    const fullMonth = ('0' + month).slice(-2)
    const day = date.getDate()
    const fullDay = ('0' + day).slice(-2)

    // eg: 1/7/2025
    if (format === 'dd/mm/YYYY') {
        return `${day}/${month}/${fullYear}`
    }

    // eg: 2025-02-05
    return `${fullYear}-${fullMonth}-${fullDay}`
}

// https://fonts.google.com/icons?icon.set=Material+Symbols&icon.query=weather&icon.size=24&icon.color=%23e8eaed
function getWeatherIconBasedOnDescription(desc: Weather[keyof Weather]['description']) {
    switch (desc) {
        case 'Clear sky':
            return 'sunny.svg'
        case 'Drizzle: Light, moderate, and dense intensity':
        case 'Rain showers: Slight, moderate, and violent':
        case 'Rain: Slight, moderate and heavy intensity':
            return 'rainy.svg'
        case 'Fog and depositing rime fog':
            return 'foggy.svg'
        case 'Freezing Drizzle: Light and dense intensity':
        case 'Freezing Rain: Light and heavy intensity':
            return 'hail.svg'
        case 'Mainly clear, partly cloudy, and overcast':
            return 'partly-cloudy.svg'
        case 'Snow fall: Slight, moderate, and heavy intensity':
        case 'Snow grains':
        case 'Snow showers slight and heavy':
            return 'snow.svg'
        case 'Thunderstorm with slight and heavy hail':
        case 'Thunderstorm: Slight or moderate':
            return 'thunder.svg'

        default:
            return null
    }
}

export function Days({ recipes, menuId, days }: { recipes: MenuRecipe[]; menuId: number; days: string[] }) {
    const { isDragging, setIsDragging } = useContext(EditMenuIsDraggingContext)

    const { data: weatherData } = useWeatherQuery()
    const { mutate: updateMenuRecipe } = useUpdateMenuRecipeMutation()

    return (
        <div className='pb-8'>
            <ul>
                {days.map((date) => {
                    const recipesOnThisDay = recipes.filter(({ day_of_week }) => day_of_week.day === date)

                    const { max_temperature, description: weatherDescription } = weatherData?.[date] ?? {}

                    const dateObj = new Date(date)
                    const readableDate = formatDate(dateObj, 'dd/mm/YYYY')
                    const dayOfWeek = getDayOfWeek(dateObj)

                    return (
                        <li key={date} className='w-full max-w-md mt-4'>
                            <Droppable
                                className={classnames('h-[32px] w-72 flex items-center rounded-lg pl-2', {
                                    'bg-primary-50 border-primary border-2 border-dashed ml-[-2px]': isDragging
                                })}
                                onDragOver={(e) => {
                                    e.dataTransfer.dropEffect = 'move'
                                }}
                                onDrop={(e) => {
                                    const data = e.dataTransfer.getData('application/my-app')
                                    const recipeId: number = JSON.parse(data)?.recipeId

                                    updateMenuRecipe({ menuId: menuId.toString(), recipeId: recipeId.toString(), attributes: { day: date } })

                                    setIsDragging(false)
                                }}
                            >
                                <div className='flex items-center gap-2'>
                                    {weatherDescription ? (
                                        <div className='flex flex-col items-center gap-0 w-6'>
                                            <img
                                                className='w-4'
                                                src={`/weather-icons/${getWeatherIconBasedOnDescription(weatherDescription)}`}
                                                alt='Weather'
                                            />
                                            <p className='text-[10px] font-medium mt-[-2px]'>{max_temperature}</p>
                                        </div>
                                    ) : null}
                                    <div className='flex items-center gap-2'>
                                        <h4 className='capitalize text-primary'>{dayOfWeek}</h4>
                                        <p className='text-xs mt-[3px] text-gray-400'>{readableDate}</p>
                                    </div>
                                </div>
                            </Droppable>

                            {recipesOnThisDay.map((recipe) => {
                                return (
                                    <div key={recipe.id} className='pl-12'>
                                        <EditMenuRecipe key={recipe.id} recipe={recipe} menuId={menuId} />
                                    </div>
                                )
                            })}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
